import React, { useCallback, useMemo } from "react";

import "typeface-rubik";
import "@fontsource/jetbrains-mono";
import {
    AppBar,
    CircularProgressCenter,
    CMSView,
    Drawer,
    FireCMS,
    ModeControllerProvider,
    NavigationRoutes,
    Scaffold,
    SideDialogs,
    SnackbarProvider,
    useBuildLocalConfigurationPersistence,
    useBuildModeController,
    useBuildNavigationController,
    useValidateAuthenticator,
} from "@firecms/core";
import {
    FirebaseAuthController,
    FirebaseLoginView,
    FirebaseSignInProvider,
    useFirebaseAuthController,
    useFirebaseStorageSource,
    useFirestoreDelegate,
    useInitialiseFirebase,
} from "@firecms/firebase";

import { firebaseConfig } from "./firebase_config";
import { meditationCategoriesCollection } from "./collections/meditationCategories";
import { useDataEnhancementPlugin } from "@firecms/data_enhancement";
import {
    useBuildUserManagement,
    userManagementAdminViews,
    useUserManagementPlugin,
} from "@firecms/user_management";
import { useImportPlugin } from "@firecms/data_import";
import { useExportPlugin } from "@firecms/data_export";
import { useFirestoreCollectionsConfigController } from "@firecms/collection_editor_firebase";
import {
    mergeCollections,
    useCollectionEditorPlugin,
} from "@firecms/collection_editor";
import { meditationsCollection } from "./collections/meditations";
import { reflectionsCollection } from "./collections/reflections";
import { reflectionCategoriesCollection } from "./collections/reflectionCategories";
import { articlesCollection } from "./collections/articles";
import { articleCategoriesCollection } from "./collections/articleCategories";
import { quotesCollection } from "./collections/quotes";
import { habitCategoriesCollection } from "./collections/habitCategories";
import { predefinedHabitsCollection } from "./collections/predefinedHabits";
import { meditationPlansCollection } from "./collections/meditationPlans";

export function App() {
    const title = "Apatheia Dashboard";

    if (!firebaseConfig?.projectId) {
        throw new Error(
            "Firebase config not found. Please check your `firebase_config.ts` file and make sure it is correctly set up."
        );
    }

    const { firebaseApp, firebaseConfigLoading, configError } =
        useInitialiseFirebase({
            firebaseConfig,
        });

    // Uncomment this to enable App Check
    // const { error: appCheckError } = useAppCheck({
    //     firebaseApp,
    //     options: {
    //         provider: new ReCaptchaEnterpriseProvider(process.env.VITE_RECAPTCHA_SITE_KEY as string)
    //     }
    // });

    /**
     * Controller used to save the collection configuration in Firestore.
     * Note that this is optional and you can define your collections in code.
     */
    const collectionConfigController = useFirestoreCollectionsConfigController({
        firebaseApp,
    });

    const collectionsBuilder = useCallback(() => {
        const collections = [
            meditationsCollection,
            meditationCategoriesCollection,
            meditationPlansCollection,
            reflectionsCollection,
            reflectionCategoriesCollection,
            articlesCollection,
            articleCategoriesCollection,
            quotesCollection,
            habitCategoriesCollection,
            predefinedHabitsCollection,
        ];
        return mergeCollections(
            collections,
            collectionConfigController.collections ?? []
        );
    }, [collectionConfigController.collections]);

    // Here you define your custom top-level views
    const views: CMSView[] = useMemo(() => [], []);

    const signInOptions: FirebaseSignInProvider[] = ["password"];

    /**
     * Controller used to manage the dark or light color mode
     */
    const modeController = useBuildModeController();

    /**
     * Delegate used for fetching and saving data in Firestore
     */
    const firestoreDelegate = useFirestoreDelegate({
        firebaseApp,
    });

    /**
     * Controller used for saving and fetching files in storage
     */
    const storageSource = useFirebaseStorageSource({
        firebaseApp,
    });

    /**
     * Controller in charge of user management
     */
    const userManagement = useBuildUserManagement({
        dataSourceDelegate: firestoreDelegate,
    });

    /**
     * Controller for managing authentication
     */
    const authController: FirebaseAuthController = useFirebaseAuthController({
        firebaseApp,
        signInOptions,
        loading: userManagement.loading,
        defineRolesFor: userManagement.defineRolesFor,
    });

    /**
     * Controller for saving some user preferences locally.
     */
    const userConfigPersistence = useBuildLocalConfigurationPersistence();

    /**
     * Use the authenticator to control access to the main view
     */
    const { authLoading, canAccessMainView, notAllowedError } =
        useValidateAuthenticator({
            authController,
            disabled: userManagement.loading,
            authenticator: userManagement.authenticator, // you can define your own authenticator here
            dataSourceDelegate: firestoreDelegate,
            storageSource,
        });

    const navigationController = useBuildNavigationController({
        collections: collectionsBuilder,
        collectionPermissions: userManagement.collectionPermissions,
        views,
        adminViews: userManagementAdminViews,
        authController,
        dataSourceDelegate: firestoreDelegate,
    });

    /**
     * Data enhancement plugin
     */
    const dataEnhancementPlugin = useDataEnhancementPlugin({
        getConfigForPath: ({ path }) => {
            if (path === "products") return true;
            return false;
        },
    });

    /**
     * User management plugin
     */
    const userManagementPlugin = useUserManagementPlugin({ userManagement });

    /**
     * Allow import and export data plugin
     */
    const importPlugin = useImportPlugin();
    const exportPlugin = useExportPlugin();

    const collectionEditorPlugin = useCollectionEditorPlugin({
        collectionConfigController,
    });

    if (firebaseConfigLoading || !firebaseApp) {
        return <CircularProgressCenter />;
    }

    if (configError) {
        return <>{configError}</>;
    }

    return (
        <SnackbarProvider>
            <ModeControllerProvider value={modeController}>
                <FireCMS
                    navigationController={navigationController}
                    authController={authController}
                    userConfigPersistence={userConfigPersistence}
                    dataSourceDelegate={firestoreDelegate}
                    storageSource={storageSource}
                    plugins={[
                        dataEnhancementPlugin,
                        importPlugin,
                        exportPlugin,
                        userManagementPlugin,
                        collectionEditorPlugin,
                    ]}
                >
                    {({ context, loading }) => {
                        let component;
                        if (loading || authLoading) {
                            component = (
                                <CircularProgressCenter size={"large"} />
                            );
                        } else {
                            if (!canAccessMainView) {
                                component = (
                                    <FirebaseLoginView
                                        allowSkipLogin={false}
                                        signInOptions={signInOptions}
                                        firebaseApp={firebaseApp}
                                        authController={authController}
                                        notAllowedError={notAllowedError}
                                    />
                                );
                            } else {
                                component = (
                                    <Scaffold
                                        logo={"logo.png"}
                                        autoOpenDrawer={false}
                                    >
                                        <AppBar title={title} />
                                        <Drawer />
                                        <NavigationRoutes />
                                        <SideDialogs />
                                    </Scaffold>
                                );
                            }
                        }

                        return component;
                    }}
                </FireCMS>
            </ModeControllerProvider>
        </SnackbarProvider>
    );
}
