import { buildCollection, buildProperty } from "@firecms/core";
import { Meditation } from "../types";
import { createLocalizedProperties } from "./locales";

// Meditations Collection
export const meditationsCollection = buildCollection<Meditation>({
    name: "Meditations",
    singularName: "Meditation",
    path: "meditations",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        categoryReference: buildProperty({
            dataType: "reference",
            name: "Category",
            path: "meditationCategories",
            validation: { required: true },
        }),
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        description: {
            dataType: "map",
            name: "Description",
            properties: createLocalizedProperties("Description", {
                multiline: true,
            }),
            validation: { required: true },
        },
        duration: {
            dataType: "array",
            name: "Duration (minutes)",
            of: {
                dataType: "number",
                validation: { min: 1, max: 120 },
            },
            validation: { required: true, min: 1 },
        },
        instructorURL: {
            dataType: "map",
            name: "Instructor URL",
            properties: createLocalizedProperties("Instructor URL", {
                url: true,
            }),
            validation: { required: true },
        },
        isPremium: {
            dataType: "boolean",
            name: "Is Premium",
            description:
                "Indicates if this meditation is only available for premium users",
            defaultValue: false,
        },
        imageUrl: {
            dataType: "string",
            name: "Image URL",
            url: true,
            validation: { required: true },
        },
    },
    id: "meditations",
});
