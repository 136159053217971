import { buildCollection } from "@firecms/core";
import { ReflectionCategory } from "../types";
import { createLocalizedProperties } from "./locales";

export const reflectionCategoriesCollection =
    buildCollection<ReflectionCategory>({
        name: "Reflection Categories",
        singularName: "Reflection Category",
        path: "reflectionCategories",
        permissions: {
            read: true,
            edit: true,
            create: true,
            delete: true,
        },
        customId: true,
        properties: {
            title: {
                dataType: "map",
                name: "Title",
                properties: createLocalizedProperties("Title"),
                validation: { required: true },
            },
            imageUrl: {
                dataType: "string",
                name: "Image URL",
                url: true,
                validation: { required: true },
            },
        },
        id: "reflection_categories",
    });
