import { buildCollection } from "@firecms/core";
import { ArticleCategory } from "../types";
import { createLocalizedProperties } from "./locales";

export const articleCategoriesCollection = buildCollection<ArticleCategory>({
    name: "Article Categories",
    singularName: "Article Category",
    path: "articleCategories",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        active: {
            dataType: "boolean",
            name: "Active",
            description: "Indicates if this category is active",
            defaultValue: true,
        },
    },
    id: "article_categories",
});
