import { buildCollection } from "@firecms/core";
import { HabitCategory } from "../types";
import { createLocalizedProperties } from "./locales";

export const habitCategoriesCollection = buildCollection<HabitCategory>({
    name: "Habit Categories",
    singularName: "Habit Category",
    path: "habitCategories",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        description: {
            dataType: "map",
            name: "Description",
            properties: createLocalizedProperties("Description", {
                multiline: true,
            }),
            validation: { required: true },
        },
        imageUrl: {
            dataType: "string",
            name: "Image URL",
            validation: { required: true },
            url: true,
        },
    },
    id: "habit_categories",
});
