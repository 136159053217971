import { buildCollection, buildProperty } from "@firecms/core";
import { Reflection } from "../types";
import { createLocalizedProperties } from "./locales";

export const reflectionsCollection = buildCollection<Reflection>({
    name: "Reflections",
    singularName: "Reflection",
    path: "reflections",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        categoryReference: buildProperty({
            dataType: "reference",
            name: "Category",
            path: "reflectionCategories",
            validation: { required: true },
        }),
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        description: {
            dataType: "map",
            name: "Description",
            properties: createLocalizedProperties("Description", {
                multiline: true,
            }),
            validation: { required: true },
        },
        prompts: {
            dataType: "array",
            name: "Prompts",
            of: {
                dataType: "map",
                properties: createLocalizedProperties("Prompt", {
                    multiline: true,
                }),
            },
            validation: { required: true, min: 1 },
        },
        isPremium: {
            dataType: "boolean",
            name: "Is Premium",
            description:
                "Indicates if this reflection is only available for premium users",
            defaultValue: false,
        },
        imageUrl: {
            dataType: "string",
            name: "Image URL",
            url: true,
            validation: { required: true },
        },
    },
    id: "reflections",
});
