import { buildCollection, buildProperty } from "@firecms/core";
import { MeditationPlan } from "../types";
import { createLocalizedProperties } from "./locales";

// MeditationPlans Collection
export const meditationPlansCollection = buildCollection<MeditationPlan>({
    name: "Meditation Plans",
    singularName: "Meditation Plan",
    path: "meditationPlans",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        description: {
            dataType: "map",
            name: "Description",
            properties: createLocalizedProperties("Description", {
                multiline: true,
            }),
            validation: { required: true },
        },
        meditationReferences: {
            dataType: "array",
            name: "Meditations",
            of: {
                dataType: "reference",
                path: "meditations",
            },
            validation: { required: true, min: 1 },
        },
        isPremium: {
            dataType: "boolean",
            name: "Is Premium",
            description:
                "Indicates if this meditation plan is only available for premium users",
            defaultValue: false,
        },
        imageUrl: {
            dataType: "string",
            name: "Image URL",
            validation: { required: true },
            url: true,
        },
    },
    id: "meditation_plans",
});
