import { buildCollection, buildProperty } from "@firecms/core";
import { Quote } from "../types";
import { createLocalizedProperties } from "./locales";

export const quotesCollection = buildCollection<Quote>({
    name: "Quotes",
    singularName: "Quote",
    path: "quotes",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        quote: {
            dataType: "map",
            name: "Quote",
            properties: createLocalizedProperties("Quote", {
                multiline: true,
            }),
            validation: { required: true },
        },
        author: {
            dataType: "map",
            name: "Author",
            properties: createLocalizedProperties("Author"),
            validation: { required: true },
        },
    },
    id: "quotes",
});
