import { buildCollection, buildProperty } from "@firecms/core";
import { PredefinedHabit } from "../types";
import { createLocalizedProperties } from "./locales";

export const predefinedHabitsCollection = buildCollection<PredefinedHabit>({
    name: "Predefined Habits",
    singularName: "Predefined Habit",
    path: "predefinedHabits",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        categoryReference: buildProperty({
            dataType: "reference",
            name: "Category",
            path: "habitCategories",
            validation: { required: true },
        }),
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        description: {
            dataType: "map",
            name: "Description",
            properties: createLocalizedProperties("Description", {
                multiline: true,
            }),
            validation: { required: true },
        },
        icon: {
            dataType: "string",
            name: "Icon",
            validation: { required: true },
        },
        info: {
            dataType: "map",
            name: "Info",
            properties: createLocalizedProperties("Info", {
                multiline: true,
            }),
            validation: { required: true },
        },
    },
    id: "predefinedHabits",
});
