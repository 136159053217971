import { buildCollection, buildProperty } from "@firecms/core";
import { Article } from "../types";
import { createLocalizedProperties } from "./locales";

export const articlesCollection = buildCollection<Article>({
    name: "Articles",
    singularName: "Article",
    path: "articles",
    permissions: {
        read: true,
        edit: true,
        create: true,
        delete: true,
    },
    customId: true,
    properties: {
        categoryId: buildProperty({
            dataType: "reference",
            name: "Category",
            path: "articleCategories",
            validation: { required: true },
        }),
        title: {
            dataType: "map",
            name: "Title",
            properties: createLocalizedProperties("Title"),
            validation: { required: true },
        },
        summary: {
            dataType: "map",
            name: "Summary",
            properties: createLocalizedProperties("Summary", {
                multiline: true,
            }),
            validation: { required: true },
        },
        parts: {
            dataType: "array",
            name: "Article Parts",
            of: {
                dataType: "map",
                properties: {
                    title: {
                        dataType: "string",
                        name: "Part Title",
                        validation: { required: true },
                    },
                    body: {
                        dataType: "string",
                        name: "Part Body",
                        multiline: true,
                        validation: { required: true },
                    },
                },
            },
            validation: { required: true, min: 1 },
        },
        authors: {
            dataType: "array",
            name: "Authors",
            of: {
                dataType: "string",
            },
            validation: { required: true, min: 1 },
        },
        isPremium: {
            dataType: "boolean",
            name: "Is Premium",
            description:
                "Indicates if this article is only available for premium users",
            defaultValue: false,
        },
        createdAt: {
            dataType: "date",
            name: "Created At",
            autoValue: "on_create",
        },
        imageUrl: {
            dataType: "string",
            name: "Image URL",
            validation: { required: true },
            url: true,
        },
    },
    id: "articles",
});
