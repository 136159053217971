import { buildCollection } from "@firecms/core";
import { MeditationCategory } from "../types";
import { createLocalizedProperties } from "./locales";

export const meditationCategoriesCollection =
    buildCollection<MeditationCategory>({
        name: "Meditation Categories",
        singularName: "Meditation Category",
        path: "meditationCategories",
        permissions: {
            read: true,
            edit: true,
            create: true,
            delete: true,
        },
        customId: true,
        properties: {
            title: {
                dataType: "map",
                name: "Title",
                properties: createLocalizedProperties("Title"),
                validation: { required: true },
            },
            active: {
                dataType: "boolean",
                name: "Active",
                description: "Indicates if this category is active",
                defaultValue: true,
            },
        },
        id: "meditation_categories",
    });
