import { buildCollection, buildEnumValues } from "@firecms/core";

const locales = buildEnumValues({
    en: "English",
    es: "Español",
    ca: "Català",
});

// Helper function to create properties for each locale
export const createLocalizedProperties = (
    name: string,
    options: { multiline?: boolean; url?: boolean } = {}
) => {
    const properties: Record<string, any> = {};
    Object.keys(locales).forEach((locale) => {
        properties[locale] = {
            dataType: "string",
            name: `${name} (${locales[locale as keyof typeof locales]})`,
            validation: { required: true },
            ...options,
        };
    });
    return properties;
};

export const localeCollection = buildCollection({
    id: "locale",
    path: "locale",
    customId: locales,
    name: "Locales",
    singularName: "Locales",
    properties: {
        name: {
            name: "Title",
            validation: { required: true },
            dataType: "string",
        },
        selectable: {
            name: "Selectable",
            description: "Is this locale selectable",
            dataType: "boolean",
        },
    },
});
